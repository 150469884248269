import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AMPieChart from '../AMPieChart/AMPieChart';
import 'bootstrap/dist/css/bootstrap.min.css';

const TableStats = ({
  columns, data, generateId, itemsPerPage,
}) => {
  const [itemsToShow, setItemsToShow] = useState(itemsPerPage);
  const currentPageData = data.slice(0, itemsToShow);
  const { t } = useTranslation();

  const sortDataByValue = (data) => data.sort((a, b) => b.value - a.value);

  const getColorFromData = (notThreatedYes, humanDocs, iaDocs, currentAccessor) => {
    if (currentAccessor === 'processedbyia') {
      if (iaDocs > 0 && notThreatedYes === 0 && humanDocs === 0) {
        return 'green';
      }
      if (iaDocs > humanDocs + notThreatedYes) {
        return 'orange';
      }
      if (iaDocs < humanDocs + notThreatedYes) {
        return 'red';
      }
      if (iaDocs === 0 && humanDocs === 0 && notThreatedYes === 0) {
        return 'black';
      }
    }
    return 'black';
  };

  // table for no scroll body
  return (
    <div className="d-flex flex-column align-items-center my-4">
      <table className="table">
        <thead className="table-content text-lg">
          <tr>
            {columns.map((column) => (
              <th key={column.accessor} className="table-titles vertical-align-middle fw-semibold">
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="overflow-hidden h-75">
          {currentPageData.map((row) => (
            <tr key={generateId()} className="text-black align-items-center">
              {columns.map((column) => (
                <td key={column.accessor}>
                  {column.accessor === 'repartition' ? (
                    <div className="w-20" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                      <AMPieChart
                        data={sortDataByValue([
                          { category: columns[1].header, value: row[columns[1].accessor] },
                          { category: columns[2].header, value: row[columns[2].accessor] },
                          { category: columns[3].header, value: row[columns[3].accessor] },
                        ])}
                        showLegend={false}
                      />
                    </div>
                  ) : (
                    <span
                      style={{
                        color: column.accessor === 'iaDocs'
                          ? getColorFromData(
                            row[columns[1].accessor],
                            row[columns[2].accessor],
                            row[columns[3].accessor],
                          )
                          : 'inherit',
                      }}
                    >
                      {row[column.accessor]}
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {itemsToShow < data.length && (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            onClick={() => setItemsToShow(itemsToShow + itemsPerPage)}
            className="btn btn-primary"
          >
            {t('Common.ShowMore')}
          </button>
        </div>
      )}
    </div>
  );
};

TableStats.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    repartition: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })),
  })).isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  generateId: PropTypes.func.isRequired,
};

export default TableStats;
