import { safeFetch } from '../helpers/response-handler';
import { queryToString } from '../helpers/utils';

const { REACT_APP_URL_CONTRACT_API } = process.env;

export const updateContract = (contractId, body) => safeFetch(
  `${REACT_APP_URL_CONTRACT_API}/v1/contract/support/contracts/owner/${contractId}`,
  {
    method: 'put',
    body: JSON.stringify(body),
  },
);

export const getContractById = (contractId) => safeFetch(
  `${REACT_APP_URL_CONTRACT_API}/v1/contract/support/contracts/${contractId}`,
  { method: 'get' },
);

export const getContractsCount = (body, type) => safeFetch(
  `${REACT_APP_URL_CONTRACT_API}/v1/contract/support/contracts/${type}`,
  {
    method: 'post',
    body: JSON.stringify(body),
  },
);

export const findContracts = (body, opts) => safeFetch(
  `${REACT_APP_URL_CONTRACT_API}/v1/contract/support/contracts${queryToString(opts)}`,
  {
    method: 'post',
    body: JSON.stringify(body),
  },
);
