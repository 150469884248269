import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5localesfrFR from '@amcharts/amcharts5/locales/fr_FR';
import { isNaN } from 'lodash';

const ContractsChart = ({ dataChart }) => {
  const chartRef = useRef(null);
  const [chartId] = useState(`chartdiv-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const root = am5.Root.new(chartId, {});
    root.setThemes([am5themesAnimated.new(root)]);
    root.dateFormatter.set('dateFormat', 'dd-MM-yyyy');
    root.locale = am5localesfrFR;

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'panX',
      wheelY: 'panY',
      layout: root.verticalLayout,
    }));

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: 'day', count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {
        grid: { template: { visible: false } },
      }),
      tooltip: am5.Tooltip.new(root, {}),
    }));

    xAxis.set('dateFormatter', am5.DateFormatter.new(root, {
      locale: am5localesfrFR,
      dateFormat: 'dd MMM yyyy',
    }));
    xAxis.get('renderer').grid.template.set('forceHidden', true);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      extraMin: 0.01,
      strictMinMax: true,
      minGridDistance: 50,
    }));
    yAxis.get('renderer').grid.template.set('forceHidden', true);

    yAxis.get('renderer').labels.template.adapters.add('text', (text) => {
      if (text) {
        const value = parseFloat(text);
        if (!isNaN(value)) {
          return Math.trunc(value).toString();
        }
      }
      return text;
    });

    const series = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis,
      yAxis,
      valueYField: 'contracts',
      valueXField: 'date',
    }));

    series.set('tooltip', am5.Tooltip.new(root, {
      labelText: '{valueY} contrats au {valueX.formatDate(dd/MM/yyyy)}',
    }));

    series.bullets.push(() => am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 2,
        fill: am5.color(0xff0000),
      }),
    }));

    chartRef.current = root;

    return () => {
      root.dispose();
    };
  }, [chartId]);

  useEffect(() => {
    if (chartRef.current && dataChart) {
      const chart = chartRef.current.container.children.getIndex(0);
      const series = chart.series.getIndex(0);
      series.data.setAll(dataChart);
    }
  }, [dataChart]);

  return <div id={chartId} style={{ width: '100%', height: '500px' }} />;
};

ContractsChart.propTypes = {
  dataChart: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    contracts: PropTypes.number.isRequired,
  })).isRequired,
};

export default ContractsChart;
