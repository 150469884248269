import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5localesfrFR from '@amcharts/amcharts5/locales/fr_FR';

const AmLinesChart = ({ config, showLegend }) => {
  const { REACT_APP_SUPPORT_HOST } = process.env;

  const chartRef = useRef(null);
  const [chartId] = useState(`chartdiv-${Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const root = am5.Root.new(chartId);
    root.setThemes([am5themesAnimated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        cursorTooltipEnabled: false, // Désactiver les tooltips du curseur
      }),
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    xAxis.set(
      'dateFormatter',
      am5.DateFormatter.new(root, {
        locale: am5localesfrFR,
        dateFormat: 'dd MMM yyyy',
      }),
    );
    xAxis.get('renderer').grid.template.set('forceHidden', true);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30,
          min: 0,
          extraMin: 0.1,
          strictMinMax: true,
        }),
      }),
    );

    yAxis.get('renderer').grid.template.set('forceHidden', true);

    const generateColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    const tabColor = [];
    config.series.forEach((seriesConfig) => {
      let color = generateColor();
      while (tabColor.includes(color)) {
        color = generateColor();
      }
      tabColor.push(color);

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: seriesConfig.name,
          xAxis,
          yAxis,
          valueYField: 'value',
          valueXField: 'date',
          stroke: am5.color(color),
          strokeWidth: 0.5,
        }),
      );

      series.bullets.push(() => am5.Bullet.new(root, {
        locationX: 0.5,
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill: series.get('fill'),
          tooltipText: seriesConfig.data[0] ? `${seriesConfig.name}: {valueY} documents au {valueX.formatDate('dd/MM/yyyy')}` : `${seriesConfig.name}: {valueY}`,
        }),
        stroke: am5.color(color),
        strokeWidth: 0.5,
      }));

      series.data.setAll(seriesConfig.data);

      series.strokes.template.states.create('hover', {
        strokeWidth: 2,
      });
    });

    const cursor = am5xy.XYCursor.new(root, {});
    chart.set('cursor', cursor);

    if (showLegend) {
      const legend = chart.rightAxesContainer.children.push(am5.Legend.new(root, {}));
      legend.set('layout', root.verticalLayout);

      legend.itemContainers.template.events.on('click', (ev) => {
        const legendItem = ev.target;
        const dataContext = legendItem.dataItem?.dataContext;
        const seriesName = dataContext?._settings?.name;
        // enable visibility for this series
        legendItem.dataItem.dataContext.set('visible', !dataContext.get('visible'));
        const series = chart.series.values.find((s) => s.name === seriesName);
        if (series) {
          series.set('visible', !series.get('visible'));
        }
        if (seriesName && !seriesName.includes('ADDW')) {
          const regex = /CUST-([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
          const match = seriesName.match(regex);

          if (match && match[1]) {
            const id = match[1];
            const url = `${REACT_APP_SUPPORT_HOST}/enterprise/${id}#nav-info-tab`;
            window.open(url, '_blank');
          }
        }
      });

      legend.itemContainers.template.events.on('pointerover', (e) => {
        const itemContainer = e.target;
        const series = itemContainer.dataItem.dataContext;

        if (series) {
          chart.series.each((chartSeries) => {
            if (chartSeries !== series) {
              chartSeries.strokes.template.setAll({
                strokeOpacity: 0.15,
                stroke: am5.color(0x000000),
              });
            } else {
              chartSeries.strokes.template.setAll({
                strokeWidth: 3,
              });
            }
          });
        }
      });

      legend.itemContainers.template.events.on('pointerout', (e) => {
        const itemContainer = e.target;
        const series = itemContainer.dataItem.dataContext;

        if (series) {
          chart.series.each((chartSeries) => {
            chartSeries.strokes.template.setAll({
              strokeOpacity: 1,
              strokeWidth: 0.5,
              stroke: chartSeries.get('fill'),
            });
          });
        }
      });

      legend.data.setAll(chart.series.values);
    }

    chartRef.current = root;
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartId, config, showLegend]);

  return <div id={chartId} style={{ width: '100%', height: '500px' }} />;
};

AmLinesChart.propTypes = {
  config: PropTypes.shape({
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  showLegend: PropTypes.bool,
};

AmLinesChart.defaultProps = {
  showLegend: true,
};

export default AmLinesChart;
